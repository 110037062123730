<script src="https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.js">
  export let firebaseApp
  import {
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
    browserLocalPersistence,
    setPersistence,
  } from 'firebase/auth'

  async function login() {
    const provider = new GoogleAuthProvider()
    const auth = getAuth(firebaseApp)

    try {
    const result = await signInWithPopup(auth, provider)
    const credential = GoogleAuthProvider.credentialFromResult(result)
        const token = credential.accessToken
    } catch (error) {
       console.log(error)
    }
  }
</script>

<button on:click={login} />

<link
  type="text/css"
  rel="stylesheet"
  href="https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.css"
/>

<style>
  button {
    background-image: url('/google_signin_buttons/web/1x/btn_google_signin_dark_normal_web.png');
    width: 191px;
    height: 46px;
    padding: 0;
    border: 0;
  }
  button:disabled {
    background-image: url('/google_signin_buttons/web/1x/btn_google_signin_dark_disabled_web.png');
    width: 191px;
    height: 46px;
    padding: 0;
    border: 0;
  }
</style>
