<script>
  export let name, firebaseApp
  import LoginButton from './LoginButton.svelte'
  import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth'

  let loggedIn
  let auth = getAuth(firebaseApp)
  onAuthStateChanged(auth,(user) => {
    if (user === null) {
      loggedIn = false
      return
    }
    loggedIn = true
    name = user.displayName
  })

  function logout() {
    signOut(auth)
  }
</script>

<main>
  {#if loggedIn}
    <h1>Hello {name}!</h1>
    <button on:click='{logout}'>Log out</button>
  {:else if loggedIn === false}
    <LoginButton firebaseApp={firebaseApp}/>
  {/if}
</main>

<style>
  main {
    text-align: center;
    padding: 1em;
    max-width: 240px;
    margin: 0 auto;
  }

  h1 {
    color: #ff3e00;
    text-transform: uppercase;
    font-size: 4em;
    font-weight: 100;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>
